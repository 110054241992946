import React, {Component} from 'react';
import {Navbar, Nav, Carousel, Container, NavDropdown, Row, Col} from 'react-bootstrap';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link

} from "react-router-dom";
import './App.css';
import './css/animate.css';

import Etusivu from './Home.js';
import Marjat from './Marjat.js';
import Tilauslomake from './Tilauslomake.js';
import PageNotFound from './PageNotFound.js';
import Yhteystiedot from "./Yhteystiedot";
import Marjatila from "./Marjatila";
import Yhteistyokumppanit from "./Yhteystyokumppanit";
import Footer from "./Footer";

export default class App extends Component {

    constructor(props) {
        super(props);

        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        var element = document.getElementsByClassName("navbar-collapse");
        element[0].classList.remove("show");
    }

    render() {
        return (
            <Router>
                <Container>
                    <Navbar bg="white" expand="lg">
                        <Navbar.Brand>
                            <img
                                 height="67"
                                 alt=""
                                 className="d-inline-block align-center"
                                 style={{'objectFit': 'scale-down'}}
                                 src={require('./img/logo.PNG')}/>
                            <img id="sirkkamerkkiimg" height="67"
                                 alt=""
                                 className="d-none d-sm-inline-block"
                                 src={require('./img/sirkkamerkki.jpg')}/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto"></Nav>
                            <Nav>
                                <Nav.Item><Link to="/" onClick={() => this.toggleNav()}>Etusivu</Link></Nav.Item>
                                <Nav.Item><Link to="/marjatila"
                                                onClick={() => this.toggleNav()}>Marjatila</Link></Nav.Item>
                                <NavDropdown title="Marjat" id="basic-nav-dropdown">
                                    <NavDropdown.Item><Link to="/marjat/mansikka"
                                                            onClick={() => this.toggleNav()}>Mansikka</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link to="/marjat/mustaherukka"
                                                            onClick={() => this.toggleNav()}>Mustaherukka</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link
                                        to="/marjat/pensasmustikka"
                                        onClick={() => this.toggleNav()}>Pensasmustikka</Link></NavDropdown.Item>
                                    <NavDropdown.Item><Link to="/marjat/vadelma"
                                                            onClick={() => this.toggleNav()}>Vadelma</Link></NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Item><Link to="/yhteistyokumppanit"
                                                onClick={() => this.toggleNav()}>Yhteistyökumppanit</Link></Nav.Item>

                                <Nav.Item><Link to="/yhteystiedot" onClick={() => this.toggleNav()}>Yhteystiedot</Link></Nav.Item>
                                <Nav.Item  className="sirkkamerkki d-block d-sm-none d-md-none d-lg-none d-xl-none">
                                    <img id="sirkkamerkkiimg" height="67"
                                         alt=""
                                         className="d-inline-block"
                                         src={require('./img/sirkkamerkki.jpg')}/>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
                <Carousel className="d-block">
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            alt=""
                            src={require('./img/banner6.jpg')}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            alt=""
                            src={require('./img/banner5.jpg')}
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            alt=""
                            src={require('./img/banner3.jpg')}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            alt=""
                            src={require('./img/banner1.jpg')}
                        />
                    </Carousel.Item>

                </Carousel>

                <Container className="margin-bottom-16">
                    <Row>
                        <Col>
                            <Routes>
                                <Route exact path="/" element={
                                    <Etusivu/>
                                    }>
                                </Route>
                                <Route exact path="/marjatila" element={
                                    <Marjatila/>
                                }>
                                </Route>
                                <Route path="/marjat/:type" element={
                                    <Marjat/>
                                }>
                                </Route>
                                <Route exact path="/tilaus" element={
                                    <Tilauslomake/>
                                }>
                                </Route>
                                <Route exact path="/yhteistyokumppanit"  element={
                                    <Yhteistyokumppanit/>
                                }>
                                </Route>
                                <Route exact path="/yhteystiedot"  element={
                                    <Yhteystiedot/>
                                }>
                                </Route>
                                <Route component={PageNotFound}/>
                            </Routes>
                            <Footer/>
                        </Col>
                    </Row>
                </Container>
            </Router>


        );
    }
}
