import React from 'react';
import './css/animate.css';
import {Col, Row} from "react-bootstrap";

export default function Etusivu() {
    return (
        <div className="animated fadeIn">
            <Row>
                <Col XS={12} className="margin-bottom-32">
                    <h2 className="animated fadeIn margin-bottom-32">
                        Pynnösen marjatila – puhtaita ja kotimaisia marjoja ympäri vuoden
                    </h2>
                    <p className="animated fadeIn">Tervetuloa Pynnösen marjatilan sivuille! Tilamme sijaitsee
                        Etelä-Savossa Kangasniemellä
                        Vaimosniemessä Puulaveden ympäröimänä. Vuosi 2018 oli marjatilallamme juhlavuosi, koska
                        silloin tuli täyteen 40 vuotta marjojen viljelyä. Marjavalikoima on laajentunut vuosien varrella
                        ja nykyään viljeltäviin marjoihin kuuluvat mansikka, pensasmustikka, puutarhavadelma ja
                        mustaherukka. </p>

                    <p>Marjatilamme on laatutarha-auditoitu tila, mikä takaa, että tuotteemme ovat 100%
                        kotimaisia ja puhtaita. Marjojen korkea laatu on aina kuulunut tärkeimpiin arvoihimme
                        vuosikymmenten ajan. Pynnösen marjatilan pellot sijaitsevat puhtaan luonnon keskellä kaukana
                        valtateistä sekä marjatilan lämpimän pienilmaston ansiosta marjoja saadaan avomaalta usein muita
                        marjatiloja aikaisemmin myyntiin. </p>

                    <p><b>Maista tuotteitamme ja ihastu!</b></p>
                </Col>
            </Row>
        </div>
    );
}
