import React, {Component} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";

class Tilauslomake extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            etunimi: "",
            sukunimi: "",
            puhelinnumero: "",
            email: "",
            kategoria: "",
            pvm: "",
            viesti: ""
        }

        this.changeCategory = this.changeCategory.bind(this);
    }

    changeCategory(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({kategoria: value});
    }

    render() {
        const {category} = this.state;

        return (
            <div>
                <h2 className="margin-bottom-32">
                    Tilauslomake
                </h2>
                <Row>
                    <Col xs={12} xl={6}>
                        <Form style={{'width': '100%'}}>
                            <Form.Group controlId="etunimi">
                                <Form.Label>Etunimi</Form.Label>
                                <Form.Control type="text" placeholder="Etunimi"/>
                            </Form.Group>

                            <Form.Group controlId="sukunimi">
                                <Form.Label>Sukunimi</Form.Label>
                                <Form.Control type="text" placeholder="Sukunimi"/>
                            </Form.Group>

                            <Form.Group controlId="puhelinnumero">
                                <Form.Label>Puhelinnumero</Form.Label>
                                <Form.Control type="phone" placeholder="Puhelinnumero"/>
                            </Form.Group>

                            <Form.Group controlId="email">
                                <Form.Label>Sähköposti</Form.Label>
                                <Form.Control type="email" placeholder="Sähköposti"/>
                            </Form.Group>

                            <Form.Group controlId="kategoria">
                                <Form.Label>Kategoria</Form.Label>
                                <Form.Control as="select" value={this.state.kategoria} onChange={(e) => this.changeCategory(e)}>
                                    <option>Valitse</option>
                                    <option value="Mansikka">Mansikka</option>
                                    <option value="Mustaherukka">Mustaherukka</option>
                                    <option value="Pensasmustikka">Pensasmustikka</option>
                                    <option value="Vadelma">Vadelma</option>
                                    <option value="Muu">Muu</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="pvm" show={category}>
                                <Form.Label>Toivottu noutopäivä</Form.Label>
                                <Form.Control type="date" placeholder="Päivämääärä"/>
                            </Form.Group>

                            <Form.Group controlId="viesti">
                                <Form.Label>Viesti</Form.Label>
                                <Form.Control as="textarea" rows="5" placeholder="Viesti"/>
                            </Form.Group>

                            <Button variant="success" type="submit">
                                Lähetä
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Tilauslomake;
