import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import Maps from "./Maps";

export default class Yhteystiedot extends Component {


    render() {

        return (
            <div className="animated fadeIn" style={{'height':'100%'}}>
                <Row>
                    <Col sm={12} md={4} className="margin-bottom-32">
                        <h2 className="margin-bottom-32">Yhteystiedot</h2>
                        <h5>Pynnösen marjatila</h5>
                        <span>Silja Seppälä</span><br/>
                        <span>Vaimosniementie 800</span><br/>
                        <span>51200 Kangasniemi</span><br/>
                        <span>044 065 2715</span><br/>
                        <span>pynnosenmarjatila@gmail.com</span>
                    </Col>
                    <Col sm={12} md={8}>
                        <Maps style={{height: '400px'}}/>

                    </Col>
                </Row>
            </div>
        );
    }
}
