import React from 'react';

export default function Footer() {
    return (

        <div id="Footer" lg={12}>
            <div style={{'marginBottom': '16px'}}>
                <span><b>Pynnösen marjatila</b></span><br/>
                <span>Vaimosniementie 800, 51200, Kangasniemi</span><br/>
                <span>puh. 044 065 2715</span>
            </div>
            <img height="67"
                 alt=""
                className="d-inline-block"
                src={require('./img/sirkkamerkki.jpg')}/>


        </div>
    );
}
