import React, {Component} from 'react';
import {Row, Col, Image, Modal, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronUp} from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery';


class Marjat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "",
            show: false,
            fileName: "",
            directory: ""
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    handleClose() {
        this.setState({show: false, fileName: "", directory: ""});
    }

    handleShow(file, dir) {
        this.setState({show: true, fileName: file, directory: dir});
    }

    componentDidMount() {
        const queryString = window.location.href;
        const id = queryString.substring(queryString.lastIndexOf('/') + 1);

        this.setState({type: id});
    }

    componentDidUpdate(prevProps, prevState) {
        var img = document.getElementById('modal-img');
        if (img !== null) {
            //or however you get a handle to the IMG
            var width = img.clientWidth;
            var height = img.clientHeight;

            if(width < height)
                $('.modal-dialog').addClass('portrait');
            else
                $('.modal-dialog').addClass('landscape');
        }


        if (prevState.type === this.state.type) return;
        const queryString = window.location.href;
        const type = queryString.substring(queryString.lastIndexOf('/') + 1);

        const element = document.getElementById(type).getBoundingClientRect();
        if (element) {
            window.scrollTo({
                top: element.y,
                behavior: "smooth"
            });
        }
    }

    render() {

        let strawberryPictures = [
            {'image': 'ad88b425-a6ec-44b4-9676-eb22932b843a.jpg', 'text': ''},
            {'image': '11160615_10206087648644245_2784199385694465866_o.jpg', 'text': ''},
            {'image': '11163784_10206185339766462_8809116996738467600_o.jpg', 'text': ''},
            {'image': '20017690_10212526259485492_3982748286759890214_o.jpg', 'text': ''},
            {'image': '20116766_10212526264525618_940805010583049843_o.jpg', 'text': ''},
            {'image': '20117126_10212526261685547_931887547184123241_o.jpg', 'text': ''},
            {'image': '36689297_10215192600342347_6860907498820337664_o.jpg', 'text': ''},
            {'image': '61986973_10217838977300117_235005105605181440_o.jpg', 'text': ''},
        ];

        let blueberryPictures = [
            {'image': '20200612_082842.jpg', 'text': ''},
            {'image': '20200612_082717.jpg', 'text': ''},
            {'image': 'fa8f9dfe-bc9d-41bc-9ae7-851582e39b6b.jpg', 'text': ''},
            {'image': '13323703_10208518685978659_4464262006178970596_o.jpg', 'text': ''},
            {'image': '11823070_10206365679394840_5758320065831317948_o.jpg', 'text': ''},
            {'image': 'picture2.jpeg', 'text': ''}
        ];

        let raspberryPictures = [
            {'image': '11754387_10206313851019163_6482063022868480537_o.jpg', 'text': ''},
            {'image': '20200612_160555.jpg', 'text': ''},
        ];

        let blackberryPictures = [
            {'image': '20200612_190432.jpg', 'text': ''},
            {'image': '20200612_190455.jpg', 'text': ''},
            {'image': '20200612_190448.jpg', 'text': ''},
            {'image': '20200612_083104.jpg', 'text': ''},
            {'image': '20200612_083121.jpg', 'text': ''},
        ];

        return (
            <div className="animated fadeIn" scrollY={this.state.scrollY}>
                <section id="mansikka" className="margin-bottom-64">
                    <h2 className="margin-bottom-16">MANSIKKA</h2>
                    <Row>
                        <Col xs={12} lg={12}>
                            <p>Mansikkaa on saatavana valmiiksi poimittuna ensisijaisesti 5kg laatikoissa. Marjat
                                poimitaan
                                tilauksesta, joten muistathan varata omasi hyvissä ajoin. Ennakkoon tilatut mansikat
                                voit
                                noutaa suoraan tilalta sovittuna ajankohtana. Muita pakkauskokoja on saatavilla
                                seuraavasti: 4kg
                                pahvilaatikko; 2kg näppärä rasia, joka sopii isommankin porukan napostelueräksi ja
                                mahtuu
                                koonsa puolesta myös hyvin jääkaappiin sekä 500g rasiat. Lajikkeina ovat varhainen
                                honeoye,
                                pääsatokauden makea polka sekä pieni määrä polkan kanssa samaan aikaan valmistuvaa
                                Lumotarta.</p>

                            <p>Viime vuosina ”hittituotteeksi” on noussut myös ns. 2.lk:n mansikka, joka on edullinen
                                vaihtoehto esim. mehujen, soseiden ja hillojen valmistukseen. Marja luokitellaan
                                2.lk:aan silloin kun
                                se ei esim. kokonsa puolesta sovi 1.lk:aan tai marja on esimerkiksi ylikypsä. 2.lk:n
                                marjan
                                saatavuus vaihtelee satotilanteen mukaan. Toisina päivinä marjaa voi olla saatavilla
                                enemmän kuin
                                toisina. 2.lk:n marjaa on saatavana jonkin verran satokaudella myös pakasteena (10 kg
                                erissä). Marjat
                                pakastetaan kantoineen, jolloin marja sopii lähinnä mehun tekoon. Pakastetusta marjasta
                                myös
                                mehun saanti on suurempi kuin tuoremarjasta.</p>

                            <p>Mansikoita on saatavana myös laatikoittain kylmäautokuljetuksena Jyväskylän Kaunisharjun
                                ”marjapysäkiltä” (os. Väliharjuntie 4). Seuraa ilmoittelua mansikkakauden aikana
                                marjatilan
                                ilmoitustaulu-sivulta. Marjapysäkkikuljetuksiin emme valitettavasti voi ottaa
                                ennakkovarauksia.</p>

                            <p><b>Huom! Tilallamme ei järjestetä itsepoimintaa.</b></p>
                        </Col>
                        {
                            strawberryPictures.map((key) => {
                                return (<Image src={require('./img/' + key.image)}
                                               onClick={() => {
                                                   this.handleShow(key.image, '');
                                               }} thumbnail/>
                                )
                            })
                        }
                    </Row>
                </section>

                <section id="mustaherukka" className="margin-bottom-64">
                    <h2 className="margin-bottom-16 margin-top-32">MUSTAHERUKKA</h2>
                    <Row>

                        <Col xs={12} lg={12}>
                            <p>Marjatilallamme viljellään sekä perinteistä mustaherukkaa (Öjebyn), että nyt uutuutena
                                muutamana kautena on valmistunut pieniä määriä uudempia ja makeampia
                                mustaherukkalajikkeita (Ben tron
                                ja Mortti).</p>
                            <p>Kaikkia lajikkeita poimimme käsin tuoreena.</p>
                        </Col>
                        {
                            blackberryPictures.map((key) => {
                                return (<Image src={require('./img/' + key.image)}
                                               onClick={() => {
                                                   this.handleShow(key.image, '');
                                               }} thumbnail/>
                                )
                            })
                        }
                    </Row>
                </section>

                <section id="pensasmustikka" className="margin-bottom-64">
                    <h2 className="margin-bottom-16 margin-top-32">PENSASMUSTIKKA</h2>
                    <Row>
                        <Col xs={12} lg={12}>
                            <p>Valtaosa viljellyistä pensasmustikoista ovat suomalaisia lajikkeita, Ainoa ja Alvaria,
                                joiden
                                maku
                                on miedon makea ja lajikkeet soveltuvat sekä tuoremarjaksi että pakastukseen. Lisäksi
                                viljelyssä on pieniä määriä isokokoista North Blue –lajiketta, joka sopii tuoremarjaksi
                                ja on
                                näyttävyytensä puolesta vaikka erinomainen kakunpäällismarja. Lisäksi on saatavana
                                pieniä määriä Siroa,
                                joka on nimensä mukaan siro ja pienikokoinen marja, mutta sitäkin makeampi. Marjat
                                pakataan
                                tilauksesta 5kg pahvilaatikkoon irtona. Tämän marjan säilyvyys jääkaapissa on hyvä,
                                usein jopa pari
                                viikkoa. Pensasmustikkaa on saatavana kaudella tuoreena 1.lk:ssa ja 2.lk:ssa sekä
                                talvella
                                pakasteena. 2.lk:ssa on vain pieni kosmeettinen haitta (kannan irtoamiskohdassa pieni
                                repeämä marjassa),
                                mutta muuten marja soveltuu kaikkeen muuhun käyttöön paitsi kakunpäällismarjaksi ei sitä
                                voi
                                suositella ulkonäkönsä vuoksi.</p>
                        </Col>
                        {
                            blueberryPictures.map((key) => {
                                return (<Image src={require('./img/' + key.image)}
                                               onClick={() => {
                                                   this.handleShow(key.image, '');
                                               }} thumbnail/>
                                )
                            })
                        }
                    </Row>
                </section>

                <section id="vadelma" className="margin-bottom-64">
                    <h2 className="margin-bottom-16 margin-top-32">VADELMA</h2>
                    <Row>
                        <Col xs={12} lg={12}>
                            <p>Vadelmaa poimimme pellolla suoraan 250g rasioihin. Lajikkeina ovat perinteinen Muskoka
                                sekä
                                isokokoinen ja näyttävä Glen ample. Myymme tilalta suoraan vadelmaa 2kg myyntierän
                                pahvilaatikoissa (250gx8) ja myös nämä marjat on hyvä tilata ennakkoon. Pakastamme
                                vadelmaa
                                myös talvikaudeksi 175g rasioihin, joten kannattaa kysellä myös niiden saatavuutta, jos
                                oman
                                pakastimen marjat loppuvatkin kesken talven. Myös vadelmaa on mahdollista saada
                                ”marjapysäkkien” kautta.</p>
                        </Col>
                        {
                            raspberryPictures.map((key) => {
                                return (<Image src={require('./img/' + key.image)}
                                               onClick={() => {
                                                   this.handleShow(key.image, '');
                                               }} thumbnail/>
                                )
                            })
                        }
                    </Row>
                </section>
                <div id="BackToTop" onClick={function () {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }}><FontAwesomeIcon icon={faChevronUp}/></div>

                <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Body>
                        {
                            (this.state.fileName !== "") ? (
                                <Image src={require("./img/" + this.state.directory + this.state.fileName)} id="modal-img"/>) : ""

                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            this.handleClose()
                        }}>
                            Sulje
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default Marjat;
