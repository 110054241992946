import React from 'react';
import {Image} from "react-bootstrap";

export default function Yhteistyokumppanit() {
    return (
        <div className="animated fadeIn margin-bottom-32">
            <h2 className="margin-bottom-32">Yhteistyökumppanit</h2>
            <p className="margin-bottom-64">Pynnösen marjatila toimittaa marjoja tukkuun, torikauppiaille ja yksityiset asiakkaat ovat tilan tuotteiden merkittäviä ostajia. Tilalta suoraan
                hakevien asiakkaiden määrä on noussut vuosi vuodelta.</p>

            <Image src={require('./img/pakkasmarja.jpg')} style={{marginRight:'16px'}} className="yhteyistyokumppani"></Image>
            <Image src={require('./img/terontuoretori.png')} style={{marginRight:'16px'}} className="yhteyistyokumppani"></Image>
            <Image src={require('./img/kasvishovi.png')} style={{marginRight:'16px'}} className="yhteyistyokumppani"></Image>

        </div>
    );
}
