import React, {Component} from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';


class Maps extends Component {
    render() {
        const mapStyles = {
            width: "92%",
            height: "400px",
        };
        return (
            <Map
                google={this.props.google}
                zoom={15}
                style={mapStyles}
                initialCenter={{ lat: 61.932315, lng: 26.731196 }}
            >
                <Marker
                    title={'Pynnösen marjatila'}
                    name={'Pynnösen marjatila'}
                    position={{lat: 61.932315, lng: 26.731196}} />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCSaIaod0FLSNNj1SBQOWRE-gAy1LEyGN4'
})(Maps);